import React from 'react';
import { Line } from 'react-chartjs-2';

import Layout from '../components/layout';
import SEO from '../components/seo';

const transparencePage = ({ pageContext }) => {
    const { chartMogulData, twitterFollowers, mastodonFollowers, githubStars, posthogVisitors } = pageContext;
    const totalVisitors = posthogVisitors?.reduce((prev, cur) => prev + cur.visitors, 0);
    const firstDate = (new Date(posthogVisitors[0]?.date)).toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' });

    return (
        <Layout>
            <SEO title="Transparence" />

            <section className="pt-28 pb-10">
                <h1 className="text-center font-bold text-3xl">Transparence</h1>
                <h2 className="text-center md:text-lg mb-8">Chiffre d'affaires, visiteurs, followers</h2>

                <div className="container m-auto px-4 lg:max-w-screen-lg grid gap-5">
                    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                        <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">Total des visiteurs (depuis le {firstDate})</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">{totalVisitors}</dd>
                        </div>
                        <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">MRR (Chiffre d'affaires mensuel récurrent)</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">{chartMogulData.currentMrr}€</dd>
                        </div>

                        <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">ARR (Chiffre d'affaires annuel récurrent)</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">{chartMogulData.currentArr}€</dd>
                        </div>
                    </dl>

                    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                        <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">Total des abonnés Twitter</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">{twitterFollowers}</dd>
                        </div>

                        <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">Total des abonnés Mastodon</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">{mastodonFollowers}</dd>
                        </div>

                        <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">Total des stars Github</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">{githubStars}</dd>
                        </div>
                    </dl>

                    <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">Évolution du MRR (Chiffre d'affaires mensuel récurrent) en €</dt>
                        <Line
                            data={{
                                labels: chartMogulData?.entries?.map((entry) => entry.date),
                                datasets: [{
                                    data: chartMogulData?.entries?.map((entry) => Math.round(entry.mrr / 100)),
                                    fill: true,
                                    borderColor: 'rgb(209, 213, 219)',
                                    tension: 0.1,
                                }],
                            }}
                            options={{ plugins: { legend: { display: false } } }}
                            className="mt-4"
                        />

                    </div>

                    <div className="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 dark:text-white truncate">Visiteurs unique</dt>
                        <Line
                            data={{
                                labels: posthogVisitors.map(({ date }) => date),
                                datasets: [{
                                    data: posthogVisitors.map(({ visitors }) => visitors),
                                    fill: true,
                                    borderColor: 'rgb(209, 213, 219)',
                                    tension: 0.1,
                                }],
                            }}
                            options={{ plugins: { legend: { display: false } } }}
                            className="mt-4"
                        />

                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default transparencePage;
